.welcome-card {
    position: relative; // Pozisyonlamayı kontrol etmek için
    overflow: hidden; // Resim Paper sınırlarını aştığında gizlemek için
    min-height: 200px;
    background:
        radial-gradient(85.51% 323.64% at 5.63% 18.87%, rgb(62, 42, 161) 15.29%, rgba(99, 36, 245, 1) 100%);

    &::after {
        // Pseudo-element ile arka plan resmi ekleme
        content: ""; // Pseudo-element için içerik
        position: absolute; // Pozisyonu absolute yaparak konumlandırma
        bottom: 0; // Alt kısma yasla
        right: -10rem; // Sağ kısma yasla
        background-image: url("../../assets/Dashboard/startercard-bg.png"); // Resim yolu
        background-size: contain; // Resmin boyutunu sığdır
        background-repeat: no-repeat; // Tekrar etmemesi için
        width: 400px;
        height: 200px;
        z-index: 0;

        @media (max-width:1198.8px) {
            width: 300px;
            height: 100px;
        }

    }

}
@media (max-width: 768px) {
    .purchased-card {
      height: 100% !important;
    }
  }