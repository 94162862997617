.fw-400{
    font-weight: 400;
}

.fw-500{
    font-weight: 500;
}

.fw-700{
    font-weight: 700;
}
