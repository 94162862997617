.antd-tab-right {
    .ant-tabs-nav {
        justify-content: end;

        .ant-tabs-nav-wrap {
            display: contents !important;
        }
    }
}

.antd-tab-text {
    .ant-tabs-tab-btn {
        font-size: 1rem !important;
        font-weight: bold;
    }
}

.antd-card-body-p-0 {
    .ant-card-body {
        padding: 0;
    }
}

.antd-collapse-bg-cloud {
    .ant-collapse-header {
        background: $cloud;
    }

    .ant-collapse-content-box {
        background: $cloud;
    }
}

.antd-order-detail-collapse {

    &.bg-white
    {
        background: #fff;
        .ant-collapse-item-active{
            background: #fff;
        }
    }

    .ant-collapse-item-active {
        border: 1px solid #F4F4F4;
        background: linear-gradient(to bottom, $cloud, #fff);
        border-radius: 1rem !important;
    }

    .ant-collapse-header,
    .ant-collapse-content-box,
    .ant-collapse-content {
        background: none !important;
    }

    // .ant-collapse-content-box {
    //     border-bottom: 1px solid $slate-blue-02;
    //     border-radius: 1rem !important;
    //     padding: 1.5rem !important;

    // }

    .ant-collapse-header {
        border-radius: 1rem !important;
        padding: 1.5rem !important;

    }
}

.antd-modal-secondary {
    .ant-modal-content {
        background: $cloud;
        border-radius: $outer-radius;
    }
    .ant-modal-title{
        background: $cloud;
    }
}

.saha-checkbox {

    span.ant-checkbox-inner {
        background: white;
        border: 1px solid #BCBCBC;

        height: 20px;
        width: 20px;
    }

    span.ant-checkbox-checked {

        span.ant-checkbox-inner {
            background: $saha-purple !important;

        }
    }
}