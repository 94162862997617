.progress-primary{
    .ant-progress-bg{
        background: $primary-color;
    }

    .ant-progress-inner{
        background: #F4F4F4;
    }
}

// .progress-slate-blue-2{
//     .ant-progress-bg{
//         background: $slate-blue-05;
//     }

//     .ant-progress-success-bg{
//         background: $slate-blue;
//     }

//     .ant-progress-inner{
//         background: white;
//     }
// }