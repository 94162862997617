.w-full {
    width: 100%;
}

.h-full {
    height: 100%;
}

.img-fluid {
    width: 100%;
    height: auto;
}

.card-h-full {
    .ant-card-body {
        height: 100%;
    }
}

.d-inline {
    display: inline;
}

.wh-8{
    width: 8px;
    height: 8px;
}

.wh-10{
    width: 10px;
    height: 10px;
}

.wh-12 {
    width: 0.75rem;
    height: 0.75rem;
}

.wh-14 {
    width: 0.825rem;
    height: 0.825rem;
}

.wh-16 {
    width: 1rem;
    height: 1rem;
}

.wh-20 {
    width: 20px;
    height: 20px;
}

.wh-25 {
    width: 25px;
    height: 25px;
}

.wh-32 {
    width: 2rem;
    height: 2rem;
}

.wh-48 {
    width: 3rem;
    height: 3rem;
}

.wh-64 {
    width: 4rem;
    height: 4rem;
}

//Height
.height-6{
    height: 6px;
}

.height-40{
    height: 40px;
}

.height-150{
    height: 150px;
}

//width
.width-64{
    width: 4rem;
}

.width-100{
    width: 100px;
}

.width-200{
    width: 200px;
}