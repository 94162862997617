//Systems
@import './systems/colors';
@import './systems/font-size';
@import './systems/font-weight';
@import './systems/radius';
@import './systems/border-radius';
@import './systems/font-colors';
@import './systems/background-colors';
@import './systems/actions';
@import './systems/margin-spacing';
@import './systems/padding-spacing';
@import './systems/w-h-classes';
@import './systems/progress';
@import './systems/buttons';


//Views 
@import './views/dashboard';
@import './views/quiz';

//Auth Layout
@import './auth-layout';

//Login Page
@import './AuthPages/login';

//Antd Classes
@import './antd-forced-classes';

html {
    background: #EFEFEF;

    .quill-border-none {
        .ql-container.ql-snow {
            border: none;
        }
    }
}

@media (max-width: 768px) {
    .sidebar-menu {
        z-index: 99999;
        position: fixed !important;
        top: 0px;
        .p-24{
            padding: 0px !important;
        }
    }
}