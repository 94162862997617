.bg-primary {
    background: $primary-color;
}

.bg-saha-purple{
    background: $saha-purple !important;
}

.bg-cloud {
    background: $cloud;
}

.bg-error {
    background: $error;
}

.bg-success {
    background: $success;
}

.bg-warning {
    background: $warning;
}

.bg-orange {
    background: $saha-orange;
}

.bg-info {
    background: $info;
}

.bg-dark {
    background: $saha-dark;
}

.btn-black{
    background: #1E1D23;
    color: #FFFFFF;
}