$primary-color: #663690;
$saha-gray: #93929A;
$saha-light-gray: rgba(197, 196, 204, 0.77);
$saha-dark-blue: #2B3674;
$saha-dark: #1E1D23;
$cloud: #F4F4F4;
$font-color: #202224;
$error: #F1416C;
$cadmium-red: #E2001A;
$success: #50CD89;
$warning: #FED449;
$saha-orange: #F47D38;
$info: #6324F5;
$saha-purple: #6324F5;