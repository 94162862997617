.border-radius-md{
    border-radius: $outer-radius;
}

.border-radius-sm{
    border-radius: $inner-radius;
}

.border-radius-lg{
    border-radius: $full-round;
}