.font-color-primary{
    color: $font-color;
}

.font-color-secondary{
    color: $primary-color
}

.font-color-white{
    color: #ffffff
}

.font-color-gray{
    color: $saha-gray
}

.font-color-dark-blue{
    color: $saha-dark-blue
}

.font-color-saha-dark{
    color: $saha-dark;
}

.font-color-light-gray{
    color: $saha-light-gray
}

.font-color-success{
    color: $success
}

.font-color-cadmium-red{
    color: $cadmium-red
}