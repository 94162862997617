.pointer {
    cursor: pointer;
}

.cursor-default {
    cursor: default;
}

.border-none {
    border: none;
}

.z-index-1 {
    z-index: 1;
}

.d-flex {
    display: flex;
}

.d-grid {
    display: flex;
}

.item-middle {
    align-items: center;
}

.content-center {
    justify-content: center;
}

.align-self-middle {
    align-self: center;
}

.text-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.text-align-center {
    text-align: center;
}

.upper-case {
    text-transform: uppercase;
}