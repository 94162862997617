.login-form{

    .section-title{
        font-size: 1.5rem;
        color: #181C32;
        margin-bottom: 0;
    }

    .section-subtitle{
        font-size: 14px;
        color: #A1A5B7;
        margin-top: 0;
    }

    .login-form-btn{
        width: 100%;
        height: 38px;
        background: #482369;
        font-size: 14px;
    }

    .login-form-btn:hover{
        background: black !important;
    }

    .form-input{
        height: 38px;
    }

    .ant-form-item{
        margin-bottom: 1rem;
    }

    .forgot-password{
        color: #482369;
    }
}