.fs-10{
    font-size: 10px;
}

.fs-12{
    font-size: 12px;
}

.fs-14{
    font-size: 14px;
}

.fs-16{
    font-size: 16px;
}

.fs-18{
    font-size: 18px;
}

.fs-20{
    font-size: 20px;
}

.fs-24{
    font-size: 24px;
}

.fs-32{
    font-size: 32px;
}

.fs-34{
    font-size: 34px;
}