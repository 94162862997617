.m-8 {
    margin: 0.5rem;
}

.m-16 {
    margin: 1rem;
}

.m-24 {
    margin: 1.5rem;
}

.m-32 {
    margin: 2rem;
}

//Margin Right
.mr-8 {
    margin-right: 0.5rem;
}

.mr-12 {
    margin-right: 0.75rem;
}

.mr-16 {
    margin-right: 1rem;
}

.mr-24 {
    margin-right: 1.5rem;
}

.mr-32 {
    margin-right: 2rem;
}

.mr-48 {
    margin-right: 3rem;
}

.mr-64 {
    margin-right: 4rem;
}

.mr-80 {
    margin-right: 5rem;
}

.mr-88 {
    margin-right: 5.5rem;
}

.mr-96 {
    margin-right: 6rem;
}

//Margin Left
.ml-4 {
    margin-left: 0.25rem;
}

.ml-8 {
    margin-left: 0.5rem;
}

.ml-12 {
    margin-left: 0.75rem;
}

.ml-16 {
    margin-left: 1rem;
}

.ml-24 {
    margin-left: 1.5rem;
}

.ml-32 {
    margin-left: 2rem;
}

.ml-48 {
    margin-left: 3rem;
}

// Margin Top
.mt-4 {
    margin-top: 0.25rem;
}

.mt-8 {
    margin-top: 0.5rem;
}

.mt-12 {
    margin-top: 0.75rem;
}

.mt-16 {
    margin-top: 1rem;
}

.mt-24 {
    margin-top: 1.5rem;
}

.mt-32 {
    margin-top: 2rem;
}

.mt-64 {
    margin-top: 4rem;
}

.mt-128 {
    margin-top: 8rem;
}

.mt-192 {
    margin-top: 12rem;
}

.mt-0{
    margin-top: 0
}

// Margin Bottom
.mb-0{
    margin-bottom: 0;
}

.mb-4{
    margin-bottom: 0.25rem;
}

.mb-8 {
    margin-bottom: 0.5rem;
}

.mb-12 {
    margin-bottom: 0.75rem;
}

.mb-16 {
    margin-bottom: 1rem;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-32 {
    margin-bottom: 2rem;
}

.mb-48{
    margin-bottom: 3rem;
}

.mb-64{
    margin-bottom: 4rem;
}

//Sıfır
.m-0{
    margin: 0;
}

.mx-0{
    margin-left: 0;
    margin-right: 0;
}

.mx-8{
    margin-left: 8px;
    margin-right: 8px;
}

.mx-16{
    margin-left: 16px;
    margin-right: 16px;
}

.mx-24{
    margin-left: 1.5rem;
    margin-right: 1.5rem;
}


.my-0{
    margin-top: 0;
    margin-bottom: 0;
}

.my-12{
    margin-top: 12px;
    margin-bottom: 12px;
}

.my-16{
    margin-top: 16px;
    margin-bottom: 16px;
}